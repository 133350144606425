import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CategoryGallery.css';

const CategoryGallery = ({ category }) => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://gusitostore.cl/getProductsByCategory.php?category=${category}`)
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          console.error(data.message);
        } else {
          setProducts(data);
        }
      })
      .catch(error => console.error('Error fetching products:', error));
  }, [category]);

  const handleProductClick = (id) => {
    navigate(`/product/${id}`); // Redirige a la página de detalles del producto
  };

  return (
    <div className="category-gallery">
      {products.map((product, index) => (
        <div key={index} className="product-card">
          <img
            src={product.imagen}
            alt={product.nombre}
            className="product-image"
            onClick={() => handleProductClick(product.id)}
          />
          <div className="product-info">
            <h3 className="product-name">{product.nombre}</h3>
            <p className="product-price">
              {parseInt(product.precio).toLocaleString('es-CL', {
                style: 'currency',
                currency: 'CLP',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </p>
            {/* Botón de seleccionar opciones */}
            <button onClick={() => handleProductClick(product.id)} className="add-to-cart-button">
              Seleccionar opciones
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoryGallery;
