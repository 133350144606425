import React, { useState, useEffect } from 'react';
import './AddProduct.css';

const AddProduct = () => {
  const [nombre, setNombre] = useState('');
  const [precio, setPrecio] = useState('');
  const [talla, setTalla] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [detalles, setDetalles] = useState('');
  const [imagen, setImagen] = useState('');
  const [tipo, setTipo] = useState('');
  const [categorias, setCategorias] = useState([]);

  // Obtener categorías desde el servidor
  useEffect(() => {
    fetch('https://gusitostore.cl/getCategories.php')  // Ajusta la URL según sea necesario
      .then((response) => response.json())
      .then((data) => {
        setCategorias(data);
      })
      .catch((error) => {
        console.error('Error al obtener las categorías:', error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validación del precio
    if (precio <= 0) {
      alert('El precio debe ser mayor que 0');
      return;
    }

    // Validación de la URL de la imagen
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!urlRegex.test(imagen)) {
      alert('Ingresa una URL válida para la imagen');
      return;
    }

    // Preparación del producto a enviar
    const newProduct = {
      nombre,
      precio: Number(precio),
      talla,
      descripcion,
      detalles,
      imagen,
      tipo: Number(tipo),  // Asegurarse de enviar el ID de la categoría
    };

    // Enviar la solicitud POST al servidor
    fetch('https://gusitostore.cl/addProduct.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newProduct),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          alert('Producto agregado exitosamente');
          // Limpiar los campos del formulario
          setNombre('');
          setPrecio('');
          setTalla('');
          setDescripcion('');
          setDetalles('');
          setImagen('');
          setTipo('');
        } else {
          alert('Error al agregar el producto: ' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Hubo un problema al enviar los datos. Intenta nuevamente.');
      });
  };

  return (
    <div className="add-product-container">
      <h2>Agregar Producto</h2>
      <form className="add-product-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nombre:</label>
          <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
        </div>

        <div className="form-group">
          <label>Precio:</label>
          <input type="number" value={precio} onChange={(e) => setPrecio(Number(e.target.value))} required />
        </div>

        <div className="form-group">
          <label>Talla:</label>
          <input type="text" value={talla} onChange={(e) => setTalla(e.target.value)} required />
        </div>

        <div className="form-group">
          <label>Descripción:</label>
          <textarea value={descripcion} onChange={(e) => setDescripcion(e.target.value)} required />
        </div>

        <div className="form-group">
          <label>Detalles:</label>
          <textarea value={detalles} onChange={(e) => setDetalles(e.target.value)} required />
        </div>

        <div className="form-group">
          <label>Imagen (URL):</label>
          <input type="text" value={imagen} onChange={(e) => setImagen(e.target.value)} required />
        </div>

        <div className="form-group">
          <label>Tipo (Categoría):</label>
          <select value={tipo} onChange={(e) => setTipo(e.target.value)} required>
            <option value="">Seleccionar categoría</option>
            {categorias.map((categoria) => (
              <option key={categoria.id} value={categoria.id}>
                {categoria.nombre}
              </option>
            ))}
          </select>
        </div>

        <button type="submit" className="add-product-btn">Agregar Producto</button>
      </form>
    </div>
  );
};

export default AddProduct;
