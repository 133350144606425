import React, { useState, useEffect } from 'react';
import './ImageGallerySix.css';
import { useNavigate } from 'react-router-dom';

const ImageGallerySix = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Inicializar useNavigate para redireccionar

  // Fetch para obtener las imágenes desde la base de datos
  useEffect(() => {
    fetch('https://gusitostore.cl/getImagesFromGallerySix.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setItems(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Hubo un problema al cargar las imágenes.');
        setLoading(false);
      });
  }, []);

  // Asignar ID del 1 al 6 basado en el orden de las imágenes
  const handleImageClick = (index) => {
    const productId = index + 1; // Asignar ID del 1 al 6 basado en la posición de la imagen
    navigate(`/product/${productId}`); // Redirigir usando el ID calculado
  };

  if (loading) {
    return <p>Cargando imágenes...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="image-gallery-container">
      <h2 className="gallery-title">NUESTROS INDISPENSABLES</h2>
      <div className="image-gallery-six">
        {items.map((item, index) => (
          <div
            key={index} // Usar el índice como clave única
            className="image-container-six"
            onClick={() => handleImageClick(index)} // Asignar ID basado en el orden de la imagen
          >
            <img
              src={item.urlbase}
              alt={item.nombreimagen}
              className="gallery-image-six"
            />
            <img
              src={item.urlhover}
              alt={`${item.nombreimagen} Hover`}
              className="hover-image-six"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallerySix;
