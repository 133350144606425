import React from 'react';
import './FullWidthImages.css';
import fondo1 from '../img/fondo1.png';
import fondo2 from '../img/fondo2.png';
import { useNavigate } from 'react-router-dom';

const FullWidthImages = () => {
  const navigate = useNavigate();

  return (
    <div className="images-wrapper">
      <div className="full-width-image-container">
        <img src={fondo1} alt="Fondo 1" className="full-width-image" />
        <img src={fondo2} alt="Fondo 2" className="mobile-full-width-image" />
        <button className="shop-now-button" onClick={() => navigate('/shop')}>
          Shop Now
        </button>
      </div>
    </div>
  );
};

export default FullWidthImages;
