import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation(); // Monitorea el cambio de ruta

  useEffect(() => {
    // Desplaza siempre al inicio de la página cada vez que cambia la ruta
    window.scrollTo(0, 0);
  }, [pathname]); // Dependencia en el cambio de ruta

  return null; // No renderiza nada en la interfaz
};

export default ScrollToTop;
