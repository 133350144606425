import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate para la navegación
import './ImageGallery.css';

const ImageGallery = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Inicializar useNavigate para redireccionar

  useEffect(() => {
    fetch('https://gusitostore.cl/getImages.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setItems(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Hubo un problema al cargar las imágenes.');
        setLoading(false);
      });
  }, []);

  const handleImageClick = (productId) => {
    // Redirigir a la página del producto basado en el id del producto
    navigate(`/product/${productId}`);
  };

  if (loading) {
    return <p>Cargando imágenes...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="gallery-container">
      {items.map((item, index) => (
        <div key={index} className="image-container" onClick={() => handleImageClick(index + 1)}>
          <img src={item.urlbase} alt={item.nombreimagen} className="gallery-image" />
          <img src={item.urlhover} alt={`${item.nombreimagen} Hover`} className="hover-image" />
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
