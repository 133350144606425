import React from 'react';
import './Nosotras.css';

const Nosotras = () => {
  return (
    <div className="nosotras-container">
      <div className="nosotras-banner">
        <h1>Sobre Nosotras</h1>
      </div>
      <div className="nosotras-content">
        <div className="nosotras-text-block">
          <h2>Nuestra Historia</h2>
          <p>
            Fundada en el corazón de Providencia, nuestra tienda de moda femenina ofrece una cuidada selección de prendas que combinan elegancia y un toque urbano. 
            Nos dedicamos a ofrecer piezas únicas que destacan la individualidad y estilo de cada mujer.
          </p>
        </div>
        <div className="nosotras-text-block">
          <h2>Nuestra Filosofía</h2>
          <p>
            Creemos en la moda como una forma de expresión personal. Nuestro objetivo es proporcionar ropa de alta calidad que no solo se vea bien, 
            sino que también haga sentir a nuestras clientas seguras y poderosas.
          </p>
        </div>
        <div className="nosotras-text-block">
          <h2>Lo Que Ofrecemos</h2>
          <p>
            Desde vestidos elegantes hasta ropa casual, nuestra tienda ofrece una amplia gama de opciones para cualquier ocasión. 
            Además, trabajamos con diseñadores emergentes para traer las últimas tendencias directamente a ti.
          </p>
        </div>
        <div className="nosotras-image-block">
          {/* Aquí puedes agregar imágenes relacionadas con la tienda */}
          <img src="ruta_a_la_imagen1.jpg" alt="Tienda de moda" />
          <img src="ruta_a_la_imagen2.jpg" alt="Ropa de moda" />
        </div>
      </div>
    </div>
  );
};

export default Nosotras;
