import React, { useState, useRef, useEffect } from 'react';
import './LogoCarousel.css';
import logo0 from '../img/logo0.png';
import logo1 from '../img/logo1.png';
import logo2 from '../img/logo2.png';
import logo3 from '../img/logo3.png';

const logos = [
  logo0, logo1, logo2, logo3, logo0, logo1, logo2, logo3,
  logo0, logo1, logo2, logo3, logo0, logo1, logo2, logo3
];

const LogoCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(Math.floor(logos.length / 2)); // Empieza con la imagen del medio
  const carouselRef = useRef(null);

  // Función para centrar la imagen
  const centerImage = (index) => {
    setCurrentIndex(index);
  };

  // Cuando cambia la imagen central, desplaza el carrusel
  useEffect(() => {
    const carouselWidth = carouselRef.current.offsetWidth;
    const slideWidth = carouselRef.current.children[0].offsetWidth;
    const scrollPosition = (slideWidth * currentIndex) - (carouselWidth / 2) + (slideWidth / 2);
    carouselRef.current.scrollTo({
      left: scrollPosition,
      behavior: 'smooth'
    });
  }, [currentIndex]);

  // Mejor sensibilidad para el arrastre
  const handleMouseDown = (e) => {
    carouselRef.current.isDragging = true;
    carouselRef.current.startX = e.pageX - carouselRef.current.offsetLeft;
  };

  const handleMouseMove = (e) => {
    if (!carouselRef.current.isDragging) return;
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - carouselRef.current.startX) * 2; // Incrementamos la sensibilidad a 2x
    carouselRef.current.scrollLeft -= walk;
  };

  const handleMouseUp = () => {
    carouselRef.current.isDragging = false;
  };

  const handleMouseLeave = () => {
    carouselRef.current.isDragging = false;
  };

  return (
    <div className="logo-carousel-container">
      <h3 className="carousel-title">COMUNIDAD YATEHEVISTO</h3>
      <div
        className="logo-carousel-wrapper"
        ref={carouselRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {logos.map((logo, index) => (
          <div
            key={index}
            className={`logo-carousel-slide ${index === currentIndex ? 'active' : ''}`}
            onClick={() => centerImage(index)}
          >
            <img src={logo} alt={`Logo ${index + 1}`} className="logo-carousel-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoCarousel;
