import React, { useState, useEffect } from 'react';
import './RelatedProducts.css';
import { useParams, useNavigate } from 'react-router-dom';

const RelatedProducts = () => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    fetch(`https://gusitostore.cl/getRelatedProducts.php?product_id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          console.error('No related products found');
        } else {
          setRelatedProducts(data);
        }
      })
      .catch((error) => {
        console.error('Error fetching related products:', error);
      });
  }, [id]);

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % relatedProducts.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? relatedProducts.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="related-products-container">
      <h3 className="related-products-title">Productos Relacionados</h3>
      <div className="related-products-grid">
        {relatedProducts.slice(currentIndex, currentIndex + 4).map((product) => (
          <div
            key={product.id}
            className="related-product-card"
            onClick={() => handleProductClick(product.id)}
          >
            <img
              src={product.imagen}
              alt={product.nombre}
              className="related-product-image"
            />
            <div className="related-product-info">
              <h4 className="related-product-name">{product.nombre}</h4>
              <p className="related-product-price">
                {parseInt(product.precio).toLocaleString('es-CL', {
                  style: 'currency',
                  currency: 'CLP',
                  minimumFractionDigits: 0,
                })}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="related-products-controls">
        <button className="related-prev-btn" onClick={handlePrevious}>
          ‹
        </button>
        <button className="related-next-btn" onClick={handleNext}>
          ›
        </button>
      </div>
    </div>
  );
};

export default RelatedProducts;
