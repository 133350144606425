import React, { useState, useEffect } from 'react';
import './Inventory.css';

const Inventory = () => {
  const [inventory, setInventory] = useState([]); // Estado para almacenar los productos
  const [loading, setLoading] = useState(true); // Estado para manejar la carga de datos

  // Función para obtener los productos de la base de datos
  const fetchInventory = async () => {
    try {
      const response = await fetch('https://gusitostore.cl/fetchFullProducts.php'); // Cambia la URL por la ruta correcta de tu backend
      const data = await response.json();
      setInventory(data); // Almacenar los productos en el estado
      setLoading(false); // Terminar la carga
    } catch (error) {
      console.error('Error al obtener el inventario:', error);
      setLoading(false);
    }
  };

  // Obtener los productos al cargar el componente
  useEffect(() => {
    fetchInventory();
  }, []);

  return (
    <div className="inventory-container">
      <h2>Inventario de Productos</h2>
      {loading ? (
        <p>Cargando inventario...</p>
      ) : (
        <table className="inventory-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Precio</th>
              <th>Talla</th>
              <th>Descripción</th>
              <th>Detalles</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            {inventory.map((item) => (
              <tr key={item.id}>
                <td>{item.nombre}</td>
                <td>{item.precio}</td>
                <td>{item.talla}</td>
                <td>{item.descripcion}</td>
                <td>{item.detalles}</td>
                <td>{item.tipo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Inventory;
