import React, { useState } from 'react';
import './SplitScreenCard.css';
import logo1 from '../img/logo1.png'; // Imagen fija grande
import prenda1 from '../img/prenda1.png';
import prenda2 from '../img/prenda2.png';
import prenda3 from '../img/prenda3.png';
import prenda4 from '../img/prenda4.png';

const items = [
  { image: prenda1, name: 'Camisa Estilo', price: '$29.99' },
  { image: prenda2, name: 'Pantalón Moderno', price: '$39.99' },
  { image: prenda3, name: 'Chaqueta Clásica', price: '$49.99' },
  { image: prenda4, name: 'Vestido Elegante', price: '$59.99' },
];

const SplitScreenCard = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  return (
    <div className="split-screen">
      <div className="left-side">
        <img src={logo1} alt="Logo Fijo" className="left-image" />
      </div>
      <div className="right-side">
        <div className="card-container">
          <div className="card-image-container">
            <img src={items[currentIndex].image} alt={items[currentIndex].name} className="card-image" />
          </div>
          <div className="item-info-card">
            <h3 className="item-name-card">{items[currentIndex].name}</h3>
            <p className="item-price-card">{items[currentIndex].price}</p>
            <button className="add-to-cart-button">Añadir al carrito</button>
            <button onClick={handleNext} className="next-button">Siguiente</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitScreenCard;
