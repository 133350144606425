import React, { createContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Importar la librería uuid para generar IDs únicas

// Crear el contexto del carrito
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  // Al cargar la aplicación, verifica si hay items en el localStorage
  useEffect(() => {
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      const parsedCart = JSON.parse(storedCart);

      // Verificamos si ha pasado el tiempo de expiración
      const currentTime = new Date().getTime();
      const expirationTime = parsedCart.expirationTime || 0;

      if (currentTime < expirationTime) {
        setCartItems(parsedCart.items);
      } else {
        localStorage.removeItem('cart'); // Limpiar si ha expirado
      }
    }
  }, []);

  // Función para guardar el carrito en localStorage con tiempo de expiración
  const saveCartToLocalStorage = (items) => {
    const expirationTime = new Date().getTime() + 5 * 60 * 1000; // Expira en 5 minutos
    const cartData = {
      items,
      expirationTime
    };
    localStorage.setItem('cart', JSON.stringify(cartData));
  };

  // Función para agregar un producto al carrito con una cantidad específica
  const addToCart = (product, quantity = 1) => {
    const existingItem = cartItems.find(item => item.id === product.id && item.size === product.size);

    if (existingItem) {
      // Si el producto ya está en el carrito con la misma talla, actualiza su cantidad
      const updatedCart = cartItems.map(item =>
        item.id === product.id && item.size === product.size
          ? { ...item, quantity: item.quantity + quantity }
          : item
      );
      setCartItems(updatedCart);
      saveCartToLocalStorage(updatedCart);
    } else {
      // Si el producto no está en el carrito, agrégalo con la cantidad especificada
      const productWithUniqueId = {
        ...product,
        cartId: uuidv4(), // Generar una ID única usando uuid
        quantity: quantity, // Establecer la cantidad seleccionada
      };
      const updatedCart = [...cartItems, productWithUniqueId];
      setCartItems(updatedCart);
      saveCartToLocalStorage(updatedCart);
    }
  };

  // Función para actualizar la cantidad de un producto en el carrito
  const updateCartQuantity = (cartId, newQuantity) => {
    const updatedCart = cartItems.map((item) =>
      item.cartId === cartId ? { ...item, quantity: newQuantity } : item
    );
    setCartItems(updatedCart);
    saveCartToLocalStorage(updatedCart); // Guardar en localStorage con tiempo de expiración
  };

  // Función para eliminar un producto del carrito
  const removeFromCart = (cartId) => {
    const updatedCart = cartItems.filter((item) => item.cartId !== cartId);
    setCartItems(updatedCart);
    saveCartToLocalStorage(updatedCart); // Guardar en localStorage con tiempo de expiración
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        updateCartQuantity,
        removeFromCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
