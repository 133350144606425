import React from 'react';
import './Tienda.css';

const Tienda = () => {
  return (
    <div className="tienda-container">
      <h1 className="tienda-title">Nuestra Tienda en Providencia</h1>
      <p className="tienda-description">
        Ubicada en el corazón de Providencia, nuestra tienda ofrece una experiencia de compra única, combinando moda de alta calidad con un servicio excepcional. Estamos en el icónico Drugstore, justo al lado de la estación de metro Providencia. ¡Ven a conocernos y descubre nuestra última colección!
      </p>
      <div className="tienda-location">
        <p><strong>Dirección:</strong> Av. Providencia 1234, Santiago, Chile</p>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.377865663247!2d-70.61424818503444!3d-33.4269009807815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf6178602f6d%3A0x5607e7f09f6b4210!2sDrugstore!5e0!3m2!1ses-419!2scl!4v1692981740804!5m2!1ses-419!2scl"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Ubicación de la tienda en Providencia"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Tienda;
