import React from 'react';
import './Banner.css';

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <div className="banner-item">Bienvenido a Mi Tienda</div>
        <div className="banner-item">Grandes Ofertas Disponibles</div>
        <div className="banner-item">¡No te lo pierdas!</div>
      </div>
    </div>
  );
};

export default Banner;
