import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const Gestion = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.name !== 'Anto') {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <div>
      <h1>Gestión de Página</h1>
      <p>Esta es una página privada, accesible solo para el usuario Anto.</p>
    </div>
  );
};

export default Gestion;
