import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop'; // Importamos ScrollToTop
import { UserProvider } from './context/UserContext';
import { CartProvider } from './context/CartContext';
import Nav from './components/Nav';
import Home from './components/Home';
import Shop from './components/Shop';
import Login from './components/Login';
import Profile from './components/Profile';
import Tienda from './components/Tienda';
import Nosotras from './components/Nosotras';
import Gestion from './components/Gestion';
import CategoryGallery from './components/CategoryGallery';
import ProductDetails from './components/ProductDetails';
import AddProduct from './components/AddProduct';
import Inventory from './components/Inventory';
import Cart from './components/Cart';
import Medidas from './components/Medidas';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Banner from './components/Banner';
import Footer from './components/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RelatedProducts from './components/RelatedProducts';


function App() {
  return (
    <UserProvider>
      <CartProvider>
        <Router>
          <ScrollToTop /> {/* ScrollToTop se aplica cada vez que cambias de ruta */}
          <Banner />
          <Nav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/shop/accesorios" element={<CategoryGallery category="Accesorios" />} />
            <Route path="/shop/chaquetas-pol" element={<CategoryGallery category="Chaquetas y Polerones" />} />
            <Route path="/shop/faldas-shorts" element={<CategoryGallery category="Faldas y Shorts" />} />
            <Route path="/shop/pantalones" element={<CategoryGallery category="Pantalones" />} />
            <Route path="/shop/tops" element={<CategoryGallery category="Tops" />} />
            <Route path="/product/:id" element={
              <>
                <ProductDetails />
                <RelatedProducts category="some-category" /> {/* Ajusta la categoría según sea necesario */}
              </>
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/add-product" element={<AddProduct />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/tienda" element={<Tienda />} />
            <Route path="/nosotras" element={<Nosotras />} />
            <Route path="/gestion" element={<Gestion />} />
            <Route path="/medidas" element={<Medidas />} />
          </Routes>
          <Footer />
          <ToastContainer />
        </Router>
      </CartProvider>
    </UserProvider>
  );
}

export default App;
