import React from 'react';
import { Instagram } from '@mui/icons-material';
import { FaTiktok } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Importamos Link
import './Footer.css'; // Archivo CSS actualizado

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section categories">
          <h3>Categorías</h3>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/shop">Shop</Link>
            </li>
            <li>
              <Link to="/nosotras">Nosotras</Link>
            </li>
            <li>
              <Link to="/tienda">Tienda</Link>
            </li>
            <li>
              <Link to="/shop/new-in">New-In</Link>
            </li>
          </ul>
        </div>

        <div className="footer-section newsletter">
          <h3>Newsletter</h3>
          <p>Únete para recibir las últimas novedades y descuentos exclusivos.</p>
          <form className="newsletter-form">
            <input type="email" placeholder="Introduce tu email" />
            <button type="submit">Suscribirse</button>
          </form>
        </div>

        <div className="footer-section social">
          <h3>Síguenos</h3>
          <ul className="social-links">
            <li className="social-item">
              <Instagram className="icon" />
            </li>
            <li className="social-item">
              <FaTiktok className="icon" />
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>© MiTienda 2022 | Todos los derechos reservados</p>
        <ul className="footer-policies">
          <li>Términos y condiciones</li>
          <li>Política de privacidad</li>
          <li>Envíos</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
