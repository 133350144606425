import React, { useContext } from 'react';
import { CartContext } from '../context/CartContext';
import CartItems from './CartItems';
import CartSummary from './CartSummary';
import './Cart.css';

const Cart = () => {
  const { cartItems, clearCart, removeFromCart } = useContext(CartContext);
  const totalPrice = cartItems.reduce((total, item) => total + Number(item.precio) * item.quantity, 0);

  return (
    <div className="cart-wrapper">
      <CartSummary totalPrice={totalPrice} clearCart={clearCart} />
      <CartItems cartItems={cartItems} removeFromCart={removeFromCart} totalPrice={totalPrice} /> {/* Pasar totalPrice a CartItems */}
    </div>
  );
};

export default Cart;
