import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ShopBanner.css';

// Definir las categorías y las imágenes correspondientes
const categories = [
  { name: 'Accesorios', path: '/shop/accesorios', image: require('../img/accesorios.png') },
  { name: 'Pantalones', path: '/shop/pantalones', image: require('../img/pantalones.png') },
  { name: 'Verano Forever <3', path: '/shop/verano-forever', image: require('../img/verano.png') },
  { name: 'Tops', path: '/shop/tops', image: require('../img/tops.png') },
  { name: 'Faldas y Shorts', path: '/shop/faldas-shorts', image: require('../img/faldas.png') },
  { name: 'Chaquetas & Polerones', path: '/shop/chaquetas-pol', image: require('../img/chaquetas.png') }
];

const ShopBanner = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="shop-banner">
      {categories.map((category, index) => (
        <div
          key={index}
          className="shop-banner-image"
          onClick={() => handleNavigate(category.path)}
          style={{ backgroundImage: `url(${category.image})` }} // Cargar imagen específica para cada categoría
        >
          <div className="shop-banner-title">{category.name}</div>
        </div>
      ))}
    </div>
  );
};

export default ShopBanner;
