import React from 'react';
import FullWidthImages from './FullWidthImages';
import WelcomeMessage from './WelcomeMessage';
import ImageGallery from './ImageGallery';
import ImageGallerySix from './ImageGallerySix';
import SplitScreenCard from './SplitScreenCard';
import LogoCarousel from './LogoCarousel';
import ShopBanner from './ShopBanner';


const Home = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <FullWidthImages /> 
      <ShopBanner />
      <WelcomeMessage />
      <ImageGallery />
      <SplitScreenCard />
      <ImageGallery />
      <ImageGallerySix />
      <LogoCarousel />
    </div>
  );
};

export default Home;
