import React, { useState, useEffect } from 'react';
import './CartSummary.css';

const CartSummary = ({ totalPrice, clearCart }) => {
  const [deliveryOption, setDeliveryOption] = useState(null);
  const [showShippingForm, setShowShippingForm] = useState(false);
  const [formError, setFormError] = useState('');
  const [formData, setFormData] = useState({
    city: '',
    comuna: '',
    villa: '',
    street: '',
    number: '',
    postalCode: '',
    phone: '',
  });

  useEffect(() => {
    const savedData = localStorage.getItem('shippingData');
    if (savedData) setFormData(JSON.parse(savedData));
  }, []);

  const handleInputChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleDeliveryOption = (option) => {
    setDeliveryOption(option);
    setShowShippingForm(option === 'shipping');
  };

  const validateShippingForm = () => {
    return Object.values(formData).every((field) => field.trim() !== '');
  };

  const handleSaveShippingInfo = () => {
    if (validateShippingForm()) {
      setFormError('');
      localStorage.setItem('shippingData', JSON.stringify(formData));
      setShowShippingForm(false);
    } else {
      setFormError('Por favor, rellena todos los campos de envío.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Gracias por tu compra');
    localStorage.removeItem('shippingData');
  };

  return (
    <div className="cart-summary-container">
      <div className={`cart-summary-content ${showShippingForm ? 'blurred' : ''}`}>
        <div className="cart-summary-total">
          <p className="cart-total-price-summary">
            Total: {totalPrice.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}
          </p>
        </div>

        <div className="cart-summary-delivery-options">
          <h3>Modalidad de entrega:</h3>
          <button onClick={() => handleDeliveryOption('storePickup')} className={deliveryOption === 'storePickup' ? 'delivery-option-selected' : 'delivery-option'}>
            Retiro en Tienda
          </button>
          <button onClick={() => handleDeliveryOption('shipping')} className={deliveryOption === 'shipping' ? 'delivery-option-selected' : 'delivery-option'}>
            Envío a Todo Chile
          </button>
        </div>

        {deliveryOption === 'storePickup' && (
          <p className="store-pickup-info">Recoge tu pedido en la tienda seleccionada.</p>
        )}
      </div>

      {/* Formulario emergente de envío */}
      {showShippingForm && (
        <div className="shipping-form-modal">
          <div className="shipping-form-content">
            <button className="close-modal-btn" onClick={() => setShowShippingForm(false)}>X</button>
            <h3>Información de Envío</h3>
            {formError && <p className="form-error">{formError}</p>}
            <form className="shipping-form">
              <div className="shipping-form-columns">
                {['city', 'comuna', 'villa', 'street', 'number', 'postalCode', 'phone'].map((field) => (
                  <div key={field} className="form-group">
                    <label>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
                    <input type="text" name={field} value={formData[field]} onChange={handleInputChange} required />
                  </div>
                ))}
              </div>
            </form>
            <button className="save-shipping-info-btn" onClick={handleSaveShippingInfo}>Guardar Datos</button>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="cart-summary-payment-form">
        {['name', 'email', 'phone'].map((field) => (
          <React.Fragment key={field}>
            <label>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
            <input type="text" name={field} value={formData[field]} onChange={handleInputChange} required />
          </React.Fragment>
        ))}
        <button type="submit" className="cart-summary-payment-button">Pagar con Webpay</button>
      </form>

      <button className="cart-summary-clear-button" onClick={clearCart}>Vaciar Carrito</button>
    </div>
  );
};

export default CartSummary;
