import React from 'react';
import './CartItems.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const CartItems = ({ cartItems, removeFromCart, totalPrice }) => {
  return (
    <div className={`cart-items-container items-${cartItems.length}`}>
      {cartItems.length === 0 ? (
        <p>No hay productos en el carrito</p>
      ) : (
        <>
          {cartItems.map((item, index) => (
            <div className="cart-product-item">
            <div className="cart-product-image-container">
              <img src={item.imagen} alt={item.nombre} className="cart-product-image" />
              <span className="product-quantity-label">{item.quantity}</span>
            </div>
            <div className="cart-product-details">
              <div className="cart-product-info">
                <h3 className="cart-product-name">{item.nombre}</h3>
                <p className="cart-product-size">Talla: {item.size}</p>
              </div>
              <p className="cart-product-price">
                {(Number(item.precio) * item.quantity).toLocaleString('es-CL', {
                  style: 'currency',
                  currency: 'CLP',
                })}
              </p>
            </div>
            <button className="cart-remove-button" onClick={() => removeFromCart(item.key)}>
              <FontAwesomeIcon icon={faTrash} /> {/* Icono de borrar */}
            </button>
          </div>

          ))}
          {/* Mostrar el precio total debajo de los items */}
          <div className="cart-total">
            <h3>Total del carrito: {totalPrice.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })}</h3>
          </div>
        </>
      )}
    </div>
  );
};

export default CartItems;
