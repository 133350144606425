import React from 'react';
import { Box, Typography } from '@mui/material';
import './WelcomeMessage.css';

const WelcomeMessage = () => {
  return (
    <Box className="welcome-container">
      <Typography 
        variant="h4" 
        className="welcome-title"
      >
        BIENVENIDO A YATEHEVISTO
      </Typography>
      <Typography 
        variant="h6" 
        className="welcome-subtitle"
      >
        Descubre nuestra nueva colección primavera/verano
      </Typography>
    </Box>
  );
};

export default WelcomeMessage;
