import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate para redirigir
import { UserContext } from '../context/UserContext'; // Importamos el contexto de usuario
import './Profile.css';

const Profile = () => {
  const navigate = useNavigate(); // Hook para navegación
  const { logoutUser } = useContext(UserContext); // Obtener logoutUser del contexto

  const goToAddProductPage = () => {
    navigate('/add-product'); // Redirigir a la ruta de agregar producto
  };

  const goToInventoryPage = () => {
    navigate('/inventory'); // Redirigir a la ruta de inventario
  };

  const handleLogout = () => {
    logoutUser(); // Llamar a la función de cerrar sesión del contexto
  };

  return (
    <div className="profile-container">
      <div className="profile-card">
        <h2>Perfil de Usuario</h2>
        <p>Nombre: Usuario Demo</p>
        <p>Email: demo@usuario.com</p>
        
        {/* Botón para redirigir a la página de agregar producto */}
        <button onClick={goToAddProductPage} className="add-item-button">
          Agregar Item
        </button>

        {/* Botón para redirigir a la página de inventario */}
        <button onClick={goToInventoryPage} className="view-inventory-button">
          Ver Inventario
        </button>

        {/* Botón para cerrar sesión */}
        <button onClick={handleLogout} className="logout-button">
          Cerrar Sesión
        </button>
      </div>
    </div>
  );
};

export default Profile;
