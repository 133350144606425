import React from 'react';
import './Medidas.css';
import medida1 from '../img/medida1.png'; // Imagen principal de la tabla de medidas
import galeriaImg1 from '../img/galeria1.jpg';
import galeriaImg2 from '../img/galeria2.jpg';
import galeriaImg3 from '../img/galeria3.jpg';
import galeriaImg4 from '../img/galeria4.jpg';
import galeriaImg5 from '../img/galeria5.jpg';

const Medidas = () => {
  return (
    <div className="medidas-container">
      <h1 className="medidas-title">TABLAS DE MEDIDAS</h1>

      <div className="medidas-main">
        <img src={medida1} alt="Tabla de Medidas" className="medidas-main-image" />
        <div className="medidas-description">
          <h2>MEDIDAS REALES</h2>
          <p>
          Nuestros moldes están hechos en base a cuerpos reales, siempre pensando en la comodidad de las prendas, es por esto que no son pequeños en comparación a lo que ofrece el Retail, si tienes alguna duda sobre tu talla o tus medidas se escapan de nuestras tablas, no dudes en contactarnos ya que realizamos prendas a medida si es necesario 🙂
          </p>
        </div>
      </div>

      <div className="galeria-container">
        <div className="galeria-row">
          <img src={galeriaImg1} alt="Galería 1" className="galeria-image" />
          <img src={galeriaImg2} alt="Galería 2" className="galeria-image" />
        </div>
        <div className="galeria-row">
          <img src={galeriaImg3} alt="Galería 3" className="galeria-image smaller" />
          <img src={galeriaImg4} alt="Galería 4" className="galeria-image smaller" />
          <img src={galeriaImg5} alt="Galería 5" className="galeria-image smaller" />
        </div>
      </div>
    </div>
  );
};

export default Medidas;
