import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom'; // Importar Link de react-router-dom
import { CartContext } from '../context/CartContext'; // Importar el contexto del carrito
import { toast } from 'react-toastify'; // Importar toastify
import 'react-toastify/dist/ReactToastify.css'; // Importar estilos de toastify
import './ProductDetails.css';

// Importar FontAwesome para íconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRuler } from '@fortawesome/free-solid-svg-icons'; // Icono de regla

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedSize, setSelectedSize] = useState(null);
  const [quantity, setQuantity] = useState(1); // Estado para la cantidad seleccionada
  const { addToCart } = useContext(CartContext); // Traer la función addToCart del contexto del carrito

  useEffect(() => {
    fetch(`https://gusitostore.cl/getProductDetails.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setProduct(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching product details:', error);
        setLoading(false);
      });
  }, [id]);

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + change;
      if (newQuantity >= 1 && newQuantity <= 10) {
        return newQuantity;
      }
      return prevQuantity;
    });
  };

  const handleAddToCart = () => {
    if (selectedSize) {
      const productToAdd = {
        ...product,
        size: selectedSize,
        quantity: quantity, // Añadir la cantidad seleccionada
      };
      addToCart(productToAdd, quantity); // Pasar el producto con la cantidad seleccionada

      // Mostrar la notificación de que se agregó al carrito
      toast.success(`Producto agregado al carrito con talla ${selectedSize} (${quantity} unidades)`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      // Mostrar advertencia si no se selecciona una talla
      toast.warn('Por favor, selecciona una talla antes de añadir al carrito', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="product-details-container">
      <div className="product-images">
        <div className="main-image">
          <img src={product.imagen} alt={product.nombre} className="product-main-img" />
        </div>
        <div className="additional-images">
          <img src={product.imagen} alt={product.nombre} className="product-secondary-img" />
          <img src={product.imagen} alt={product.nombre} className="product-secondary-img" />
          <img src={product.imagen} alt={product.nombre} className="product-secondary-img" />
        </div>
      </div>
      <div className="product-info-card">
        <h1 className="product-name">{product.nombre}</h1>
        <p className="product-price">${product.precio}</p>
        <p className="product-description">{product.descripcion}</p>

        {/* Enlace a la página de medidas con ícono */}
        <div className="size-guide-link">
          <Link to="/medidas">
            <FontAwesomeIcon icon={faRuler} className="icon-ruler" /> Consulta la guía de medidas
          </Link>
        </div>

        <div className="product-sizes">
          <div className="sizes-container">
            {['S', 'M', 'L', 'XL'].map((size) => (
              <button
                key={size}
                className={`size-button ${selectedSize === size ? 'selected' : ''}`}
                onClick={() => handleSizeClick(size)}
              >
                {size}
              </button>
            ))}
          </div>
        </div>

        <h3>
          Talla elegida: <span className="size-box">{selectedSize ? selectedSize : 'X'}</span>
        </h3>

        {/* Botones para seleccionar la cantidad */}
        <div className="quantity-selector">
          <h3>Cantidad:</h3>
          <button
            className="quantity-button"
            onClick={() => handleQuantityChange(-1)}
            disabled={quantity <= 1}
          >
            -
          </button>
          <span className="quantity">{quantity}</span>
          <button
            className="quantity-button"
            onClick={() => handleQuantityChange(1)}
            disabled={quantity >= 10}
          >
            +
          </button>
        </div>

        <button
          className="add-to-cart-btn"
          onClick={handleAddToCart}
          disabled={!selectedSize} // Deshabilitar si no se selecciona una talla
        >
          Añadir al carrito
        </button>
      </div>
    </div>
  );
};

export default ProductDetails;
