import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    let logoutTimer;
    if (user) {
      logoutTimer = setTimeout(() => {
        alert('You have been logged out due to inactivity.');
        setUser(null);
        window.location.href = '/'; // Redirigir al home
      }, 300000); // 5 minutos

      return () => clearTimeout(logoutTimer); // Limpiar timeout si el usuario cierra sesión manualmente
    }
  }, [user]);

  const loginUser = (userData) => {
    setUser(userData);
  };

  const logoutUser = () => {
    setUser(null);
    window.location.href = '/';
  };

  return (
    <UserContext.Provider value={{ user, loginUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};
